
import Sidebar from "@/components/Nav/Sidebar/Sidebar.vue";

export default {
	name: 'DeveloperGuide',
	components: {Sidebar},
	props: {
		ApiCommand1: {
			type: String,
			default: 'template\n' +
				'            nav class=rounded-2xl bg-white shadow-xl\n' +
				'            div class=max-w-7xl mx-auto px-2 sm:px-6 lg:px-8\n' +
				'            div class=relative flex items-center justify-between h-16\n' +
				'            div class=absolute inset-y-0 left-0 flex items-center sm:hidden',
		},
		ApiCommand2: {
			type: String,
			default: 'template\n' +
				'            nav class=rounded-2xl bg-white shadow-xl\n' +
				'            div class=max-w-7xl mx-auto px-2 sm:px-6 lg:px-8\n' +
				'            div class=relative flex items-center justify-between h-16\n' +
				'            div class=absolute inset-y-0 left-0 flex items-center sm:hidden',
		},
	},
	setup() {
		return {};
	}
}
